import "reflect-metadata";

import "./themes/default/default.css";
import "./themes/globals.css";

import ApiClient from "@lib/ApiClient";
import AuthApiClient from "@lib/ApiClient/AuthApiClient";
import GrowaveTokenManager from "@lib/TokenManager/GrowaveTokenManager";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import { CURRENT_APP_QUERY_PARAMETER } from "./constants/current_app";

export const createApiClients = (
    appProxyPrefix: string,
    logger: LoggerInterface,
    currentApp: string,
    tokenManager: GrowaveTokenManager
) => {
    // php backend(instagram/ticktok)
    const phpApiClient = new ApiClient(
        `${appProxyPrefix}/storefront-api/v2/`,
        undefined,
        {
            [CURRENT_APP_QUERY_PARAMETER]: currentApp,
        },
        logger
    );
    const phpAuthApiClient = new AuthApiClient(phpApiClient, tokenManager);
    // social login
    const socialLoginApiClient = new ApiClient(
        `${appProxyPrefix}/storefront-api/social-login-app/v2`,
        undefined,
        {
            [CURRENT_APP_QUERY_PARAMETER]: currentApp,
        },
        logger
    );
    const socialLoginAuthApiClient = new AuthApiClient(
        socialLoginApiClient,
        tokenManager
    );
    // rewards
    const rewardsApiClient = new ApiClient(
        `${appProxyPrefix}/storefront-api/rewards-storefront/v2`,
        undefined,
        {
            [CURRENT_APP_QUERY_PARAMETER]: currentApp,
        },
        logger
    );
    const rewardsAuthApiClient = new AuthApiClient(
        rewardsApiClient,
        tokenManager
    );
    // metrics collector
    const metricsCollectorApiClient = new AuthApiClient(
        new ApiClient(
            `${appProxyPrefix}/metrics-collector/`,
            undefined,
            {
                [CURRENT_APP_QUERY_PARAMETER]: currentApp,
            },
            logger
        ),
        tokenManager
    );

    return {
        phpApiClient,
        phpAuthApiClient,
        socialLoginApiClient,
        socialLoginAuthApiClient,
        rewardsApiClient,
        rewardsAuthApiClient,
        metricsCollectorApiClient,
    };
};

import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import CurrencyProviderInterface from "@lib/CurrencyProvider/CurrencyProviderInterface";
import CustomerInfoProvider from "@lib/CustomerInfoProvider/CustomerInfoProvider";
import EventsCollectorInterface from "@lib/EventsCollector/EventsCollectorInterface";
import FetchAndXHRPatcher from "@lib/FetchAndXHRPatcher/FetchAndXHRPatcher";
import GwI18n from "@lib/GwI18n/GwI18n";
import HTMLSanitizerInterface from "@lib/HTMLSanitizer/HTMLSanitizerInterface";
import LocationControllerInterface from "@lib/LocationController/LocationControllerInterfce";
import MoneyExchangerInterface from "@lib/MoneyExchanger/MoneyExchangerInterface";
import MoneyFormatterInterface from "@lib/MoneyFormatter/MoneyFormatterInterface";
import { ReferrerProviderInterface } from "@lib/ReferrerProvider";
import SettingsManagerInterface from "@lib/SettingsManager/SettingsManagerInterface";
import TokenManagerInterface from "@lib/TokenManager/TokenManagerInterface";
import CartListenerInterface from "@modules/cart/utils/CartListener/CartListenerInterface";
import CartManagerCounterUpdatersContext from "@modules/cart/utils/CartManager/CartManagerCounterUpdatersContext";
import type CartManagerInterface from "@modules/cart/utils/CartManager/CartManagerInterface";
import CartManagerItemsUpdatersContext from "@modules/cart/utils/CartManager/CartManagerItemsUpdatersContext";
import { TrackingApiService } from "@modules/tracking_events/services/TrackingApiService";
import { InjectionToken } from "tsyringe";

import GwStorefrontAppInfo from "@interfaces/GwStorefrontAppInfo";
import { LoggerInterface } from "@interfaces/LoggerInterface";

export const eventsCollectorToken = Symbol(
    "eventsCollectorToken"
) as InjectionToken<EventsCollectorInterface>;

// API Clients
export const phpAuthApiClientToken = Symbol(
    "authApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const phpApiClientToken = Symbol(
    "apiClientToken"
) as InjectionToken<ApiClientInterface>;

export const socialLoginApiClientToken = Symbol(
    "socialLoginApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const socialLoginAuthApiClientToken = Symbol(
    "socialLoginAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const rewardsAuthApiClientToken = Symbol(
    "rewardsAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

//
export const tokenManagerToken = Symbol(
    "tokenManagerToken"
) as InjectionToken<TokenManagerInterface>;

export const globalLoggerToken = Symbol(
    "globalLoggerToken"
) as InjectionToken<LoggerInterface>;

export const gwStorefrontAppInfoToken = Symbol(
    "authApiClientToken"
) as InjectionToken<GwStorefrontAppInfo>;

export const locationControllerToken = Symbol(
    "locationControllerToken"
) as InjectionToken<LocationControllerInterface>;

export const cartManagerToken = Symbol(
    "cartManagerToken"
) as InjectionToken<CartManagerInterface>;

export const cartManagerItemsUpdatersContextToken = Symbol(
    "cartManagerItemsUpdatersContextToken"
) as InjectionToken<CartManagerItemsUpdatersContext>;

export const cartManagerCounterUpdatersContextToken = Symbol(
    "cartManagerCounterUpdatersContextToken"
) as InjectionToken<CartManagerCounterUpdatersContext>;

export const settingsManagerToken = Symbol(
    "settingsManagerToken"
) as InjectionToken<SettingsManagerInterface>;

export const moneyFormatterToken = Symbol(
    "moneyFormatterToken"
) as InjectionToken<MoneyFormatterInterface>;

export const moneyExchangerToken = Symbol(
    "moneyExchangerToken"
) as InjectionToken<MoneyExchangerInterface>;

export const currencyProviderToken = Symbol(
    "currencyProvider"
) as InjectionToken<CurrencyProviderInterface>;

export const cartListenerToken = Symbol(
    "cartListener"
) as InjectionToken<CartListenerInterface>;

export const referrerProviderToken = Symbol(
    "referrerProvider"
) as InjectionToken<ReferrerProviderInterface>;

export const fetchAndXhrPatcherToken = Symbol(
    "fetchAndXhrPatcher"
) as InjectionToken<FetchAndXHRPatcher>;

export const htmlSanitizerToken = Symbol(
    "htmlSanitizer"
) as InjectionToken<HTMLSanitizerInterface>;

export const i18nToken = Symbol("i18nToken") as InjectionToken<GwI18n>;

export const trackingApiServiceToken = Symbol(
    "i18nToken"
) as InjectionToken<TrackingApiService>;

export const customerInfoProviderToken = Symbol(
    "customerInfoProviderToken"
) as InjectionToken<CustomerInfoProvider>;

export const sharedTokens = {
    cartManagerItemsUpdatersContextToken,
    cartManagerCounterUpdatersContextToken,
    globalLoggerToken,
    eventsCollectorToken,
};

import "reflect-metadata";

import "./themes/default/default.css";
import "./themes/globals.css";

import {
    InstagramApplicationConfigInterface,
    InstagramApplicationConfigToken,
} from "@apps/instagram/InstagramApplicationConfig";
import {
    TikTokApplicationConfigInterface,
    TikTokApplicationConfigToken,
} from "@apps/tiktok/TikTokApplicationConfig";
import ApiClient from "@lib/ApiClient";
import FromShopifyCurrencyCurrencyProvider from "@lib/CurrencyProvider/FromShopifyCurrencyCurrencyProvider";
import MetricsEvent from "@lib/EventsCollector/Event";
import RxJSEventsCollector from "@lib/EventsCollector/RxJSEventsCollector";
import EventsReporter from "@lib/EventsReporter/EventsReporter";
import FetchAndXHRPatcher from "@lib/FetchAndXHRPatcher/FetchAndXHRPatcher";
import GrowaveFeaturesProvider from "@lib/GrowaveFeaturesProvider";
import GwCurrency from "@lib/GwCurrency/GwCurrency";
import GwI18n from "@lib/GwI18n/GwI18n";
import ScriptTagLoader from "@lib/GwI18n/I18nScriptTagLoader";
import HistoryPatcher from "@lib/HistoryPatcher";
import XSSHTMLSanitizer from "@lib/HTMLSanitizer/XSSHTMLSanitizer";
import LocationController from "@lib/LocationController/LocationController";
import LegacyMoneyExchanger from "@lib/MoneyExchanger/LegacyMoneyExchanger";
import MoneyFormatter from "@lib/MoneyFormatter/MoneyFormatter";
import ReferrerProvider from "@lib/ReferrerProvider";
import Timer from "@lib/timer";
import GrowaveTokenManager from "@lib/TokenManager/GrowaveTokenManager";
import CartListener from "@modules/cart/utils/CartListener/CartListener";
import FetchOrXHRPatcherListenerStrategy from "@modules/cart/utils/CartListener/FetchOrXHRPatcherListenerStrategy";
import SendFormListenerStrategy from "@modules/cart/utils/CartListener/SendFormListenerStrategy";
import CartManagerCounterUpdatersContext from "@modules/cart/utils/CartManager/CartManagerCounterUpdatersContext";
import CartManagerItemsUpdatersContext from "@modules/cart/utils/CartManager/CartManagerItemsUpdatersContext";
import ShopifyCartManager from "@modules/cart/utils/CartManager/ShopifyCartManager";
import { TrackingApiService } from "@modules/tracking_events/services/TrackingApiService";
import { lastValueFrom } from "rxjs";
import { container } from "tsyringe";

import GwStorefrontAppInfo from "@interfaces/GwStorefrontAppInfo";

import CheckService from "./apps/login/services/CheckService/CheckService";
import { CURRENT_APP_INSTAGRAM } from "./constants/current_app";
import { GP_GW_REFFERER } from "./constants/get_params";
import { createApiClients } from "./createApiClients";
import { MinilogLogger } from "./lib/Logger";
import ShopifyCartApiService from "./modules/cart/services/CartService";
import MetricsCollectorService from "./services/MetricsCollectorService";
import RefreshTokenService from "./services/RefreshTokenService";
import {
    cartListenerToken,
    cartManagerCounterUpdatersContextToken,
    cartManagerItemsUpdatersContextToken,
    cartManagerToken,
    currencyProviderToken,
    eventsCollectorToken,
    fetchAndXhrPatcherToken,
    globalLoggerToken,
    gwStorefrontAppInfoToken,
    htmlSanitizerToken,
    i18nToken,
    locationControllerToken,
    moneyExchangerToken,
    moneyFormatterToken,
    phpApiClientToken,
    phpAuthApiClientToken,
    referrerProviderToken,
    sharedTokens,
    socialLoginApiClientToken,
    socialLoginAuthApiClientToken,
    tokenManagerToken,
    trackingApiServiceToken,
} from "./tokens";

async function __gwMain() {
    if (typeof window !== "undefined") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        (window as any).__GW_CONTAINER__ = container;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        (window as any).__GW_TOKENS__ = sharedTokens;
    }
    const storefrontAppInfo: GwStorefrontAppInfo = {
        appName: __APP_NAME__,
        version: __VERSION__,
        inIframe: window.top !== window.self,
        designMode: !!Shopify.designMode,
        shopName: Shopify.shop,
        currentApp: CURRENT_APP_INSTAGRAM,
    }; // logger
    container.registerInstance(gwStorefrontAppInfoToken, storefrontAppInfo);

    const globalLogger = new MinilogLogger("global");
    globalLogger.info("Growave main script is initialized!");
    container.registerInstance(globalLoggerToken, globalLogger);

    // token manager
    const tokenManager = new GrowaveTokenManager(
        new RefreshTokenService(new ApiClient(), globalLogger),
        localStorage,
        globalLogger
    );
    container.registerInstance(tokenManagerToken, tokenManager);

    // register api clients
    const {
        phpApiClient,
        phpAuthApiClient,
        socialLoginApiClient,
        socialLoginAuthApiClient,
        metricsCollectorApiClient,
    } = createApiClients(
        "/apps/ssw-instagram",
        globalLogger,
        CURRENT_APP_INSTAGRAM,
        tokenManager
    );

    container.registerInstance(phpApiClientToken, phpApiClient);
    container.registerInstance(phpAuthApiClientToken, phpAuthApiClient);
    container.registerInstance(socialLoginApiClientToken, socialLoginApiClient);
    container.registerInstance(
        socialLoginAuthApiClientToken,
        socialLoginAuthApiClient
    );

    // UTILS
    // (utils) location controller
    const locationController = new LocationController(window.location);
    container.registerInstance(locationControllerToken, locationController);
    // (utils) history patcher
    const historyPatcher = new HistoryPatcher(
        [GP_GW_REFFERER],
        window.history,
        locationController
    );
    historyPatcher.patch();
    // (utils) fetchAndXHRPatcher
    const fetchAndXHRPatcher = new FetchAndXHRPatcher(globalLogger);
    fetchAndXHRPatcher.patch();
    container.registerInstance(fetchAndXhrPatcherToken, fetchAndXHRPatcher);
    // (utils) referrerProvider
    const referrerProvider = new ReferrerProvider();
    container.registerInstance(referrerProviderToken, referrerProvider);
    // (utils) check service
    const checkService = new CheckService(
        socialLoginApiClient,
        tokenManager,
        globalLogger
    );
    await lastValueFrom(checkService.check());
    // (utils) metrics collector
    const metricsCollectorService = new MetricsCollectorService(
        metricsCollectorApiClient,
        globalLogger
    );
    const eventsCollector = new RxJSEventsCollector();
    const eventsReporter = new EventsReporter(
        eventsCollector,
        metricsCollectorService,
        {
            app: __APP_NAME__,
            env: __APP_ENV__,
            userAgent: navigator.userAgent,
            version: __VERSION__,
        }
    );
    eventsReporter.start();
    container.registerInstance(eventsCollectorToken, eventsCollector);
    // (utils) cart manager
    const cartManagerItemsUpdatersContext =
        new CartManagerItemsUpdatersContext();
    container.registerInstance(
        cartManagerItemsUpdatersContextToken,
        cartManagerItemsUpdatersContext
    );
    const cartManagerCounterUpdatersContext =
        new CartManagerCounterUpdatersContext();
    container.registerInstance(
        cartManagerCounterUpdatersContextToken,
        cartManagerCounterUpdatersContext
    );
    container.registerInstance(
        cartManagerToken,
        new ShopifyCartManager(
            new ShopifyCartApiService(new ApiClient("/"), globalLogger),
            globalLogger,
            cartManagerCounterUpdatersContext,
            cartManagerItemsUpdatersContext
        )
    );
    // (utils) cart listener
    const cartListener = new CartListener(globalLogger);
    cartListener.register(new SendFormListenerStrategy(globalLogger));
    cartListener.register(
        new FetchOrXHRPatcherListenerStrategy(globalLogger, fetchAndXHRPatcher)
    );
    container.registerInstance(cartListenerToken, cartListener);

    // (utils) I18n
    const scriptTagLoader = new ScriptTagLoader("#gw-critical-translations");
    const i18n = new GwI18n(globalLogger, eventsCollector);
    i18n.registerLoader(scriptTagLoader);
    void i18n.load();
    container.registerInstance(i18nToken, i18n);

    // (utils) currencyProvider
    const currencyProvider = new FromShopifyCurrencyCurrencyProvider(
        globalLogger
    );
    container.registerInstance(currencyProviderToken, currencyProvider);

    // (utils) featuresProvider
    const featuresProvider = new GrowaveFeaturesProvider(globalLogger);
    const features = featuresProvider.getFeatures();

    // (utils) moneyFormatter
    const moneyFormatter = new MoneyFormatter(
        features.moneyFormat,
        features.moneyWithCurrencyFormat
    );
    container.registerInstance(moneyFormatterToken, moneyFormatter);

    // (utils) GwCurrency
    const gwCurrency = new GwCurrency(globalLogger, new ApiClient());
    gwCurrency.loadRates();

    // (utils) moneyExchanger
    const moneyExchanger = new LegacyMoneyExchanger(
        features.shopCurrency,
        gwCurrency
    );
    container.registerInstance(moneyExchangerToken, moneyExchanger);

    // (utils) HTMLSanitizer
    const HTMLSanitizer = new XSSHTMLSanitizer(globalLogger, {
        span: ["class", "id"],
        div: ["class", "id"],
        p: ["class", "id"],
        i: ["class", "id"],
        b: ["class", "id"],
        pre: ["class", "id"],
    });
    container.registerInstance(htmlSanitizerToken, HTMLSanitizer);

    // (utils) TrackingApiService
    const trackingApiService = new TrackingApiService(
        phpAuthApiClient,
        globalLogger
    );
    container.registerInstance(trackingApiServiceToken, trackingApiService);

    eventsCollector.pushEvent(
        new MetricsEvent("loadScript.Main", new Date(), {
            metricValue: new Date().getTime() - performance.timing.domLoading,
        })
    );

    // Instagram
    container.register<InstagramApplicationConfigInterface>(
        InstagramApplicationConfigToken,
        {
            useValue: {
                placeholderSelector: ".gw-instagram-gallery-placeholder",
            },
        }
    );
    const startLoadingInstagramApplicationTimer = new Timer();
    void import(
        /*webpackChunkName: "InstagramApplication"*/ "@apps/instagram/InstagramApplication"
    ).then(({ InstagramApplication }) => {
        const duration = startLoadingInstagramApplicationTimer.ready();
        eventsCollector.pushEvent(
            new MetricsEvent("loadScript.InstagramApplication", new Date(), {
                metricValue: duration,
            })
        );
        const instagramApplication = container.resolve(InstagramApplication);
        instagramApplication.init();
    });
    // Tiktok
    container.register<TikTokApplicationConfigInterface>(
        TikTokApplicationConfigToken,
        {
            useValue: {
                placeholderSelector: ".gw-tiktok-gallery-placeholder",
            },
        }
    );
    const startLoadingTikTokApplicationTimer = new Timer();
    void import(
        /*webpackChunkName: "TikTokApplication" */ "@apps/tiktok/TikTokApplication"
    ).then(({ TikTokApplication }) => {
        const duration = startLoadingTikTokApplicationTimer.ready();
        eventsCollector.pushEvent(
            new MetricsEvent("loadScript.TikTokApplication", new Date(), {
                metricValue: duration,
            })
        );

        const tiktokApplication = container.resolve(TikTokApplication);
        tiktokApplication.init();
    });

    const startLoadingWarningModalTimer = new Timer();
    void import(
        /*webpackChunkName: "WarningModalWidget" */ "@widgets/warning_modal_widget/WarningModalWidget"
    ).then(({ WarningModalWidget }) => {
        const duration = startLoadingWarningModalTimer.ready();
        eventsCollector.pushEvent(
            new MetricsEvent("loadScript.WarningModalWidget", new Date(), {
                metricValue: duration,
            })
        );

        const warningModalWidget = container.resolve(WarningModalWidget);
        warningModalWidget.init("Shop Instagram & UGC");
    });

    if (typeof window !== "undefined") {
        window.dispatchEvent(new Event("GW_MAIN_SCRIPT_LOADED"));
    }
}

void __gwMain();
